import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/element.js'
import vuetify from './plugins/vuetify'
import BaiduMap from 'vue-baidu-map'
//导入全局样式
import './assets/css/global.css'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '8d65rdjGkU3vC2q3EusY0rdY6I4iFRGK'
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
