import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('views/Home')  //主页
const Footer = () => import('views/Footer')  //页脚
const HouseManage = () => import('views/general_page/HouseManage')  //用户管理
const PrimaryHome = () => import('views/general_page/PrimaryHome')  //首页(超级、普通)
const Login = () => import('views/general_page/Login')  //登录
const FindPwd = () => import('views/general_page/FindPwd')  //找回密码
const Personal = () => import('views/general_page/Personal')  //个人中心
const UserManage_O = () => import('views/Ordinary_admin/UserManage_O')  //用户管理 普通管理员
const UserManage_Lease = () => import('views/Ordinary_admin/UserManage_lease')   //用户管理 超级管理员
const UserHome = () => import('views/general_user/UserHome')  //首页(普通用户)
const DailyHistory = () => import('views/general_page/DailyHistory')  //日常管理(历史记录)
const DailyManage = () => import('views/general_user/DailyManage')  //日常管理
const HouseSafetyInfo = () => import('views/Super_admin/HouseSafetyInfo')  //安全信息
const PostManage = () => import('views/Super_admin/PostManage')  //公告
const SafetyIndexManage = () => import('views/Super_admin/SafetyIndexManage')  //安全指标管理
const DailyIndexManage = () => import('views/Super_admin/DailyIndexManage')  //报送指标管理
const LeaseManage = () => import('views/general_page/LeaseManage')  //安全指标管理


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        meta: {title: '首页'},
        component: Home,
        redirect: '/primaryHome',
        children: [
            {
                path: '/primaryHome',
                component: PrimaryHome,
                meta: {title: '信息主页'},
            },
            {
                path: '/userHome',
                component: UserHome,
                meta: {title: '信息主页'},
            },
            {
                path: '/houseManage',
                component: HouseManage,
                meta: {title: '房屋信息'},
            },
            {
                path: '/personal',
                component: Personal,
                meta: {title: '个人中心'},
            },
            {
                path: '/userManage_o',
                component: UserManage_O,
                meta: {title: '区域管理员'},
            },
            {
                path: '/userManage_lease',
                component: UserManage_Lease,
                meta: {title: '区域租赁员'},
            },
            {
                path: '/dailyHistory',
                component: DailyHistory,
                meta: {title: '日常信息报送历史'},
            },
            {
                path: '/dailyManage',
                component: DailyManage,
                meta: {title: '日常管理'},
            },
            {
                path: '/houseSafetyInfo',
                component: HouseSafetyInfo,
                meta: {title: '安全信息录入'},
            },
            {
                path: '/postManage',
                component: PostManage,
                meta: {title: '公告管理'},
            },
            {
                path: '/safetyIndexManage',
                component: SafetyIndexManage,
                meta: {title: '安全指标'},
            },
            {
                path: '/dailyIndexManage',
                component: DailyIndexManage,
                meta: {title: '报送指标'},
            },
            {
                path: '/leaseManage',
                component: LeaseManage,
                meta: {title: '租赁管理'},
            },
        ]
    },
    {
        path: '/footer',
        component: Footer,
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/findPwd',
        component: FindPwd
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/login') return next();
    //获取token
    const tokenStr = window.localStorage.getItem('token');
    if (!tokenStr) return next('/login');
    next();
})
export default router
